import queryString from 'querystring';

export const getAddressFromQueryString = (search: string | undefined) => {
    if (search) {
        const truncatedSearch = search.replace('?', '');
        const latitude = parseFloat(queryString.parse(truncatedSearch).lat as string);
        const longitude = parseFloat(queryString.parse(truncatedSearch).lng as string);
        const label = queryString.parse(truncatedSearch).label as string;

        if (isNaN(latitude) || isNaN(longitude)) {
            return;
        } else {
            return {
                position: {
                    latitude,
                    longitude,
                },
                label: label ? label : `${latitude}, ${longitude}`,
            };
        }
    }
};
