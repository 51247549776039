import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Dispatch, State } from '../../types';
import MapWrapper from './MapWrapper';
import { getHereCredentials, getHereCredentialsError } from '../reducers/selectors';
import { fetchHereConfig } from '../actions/hereConfigActions';
import { MapErrorState } from './MapErrorState';
import { MapErrorBoundary } from './MapErrorBoundary';
import { MainLoadingIndicator } from '../app/MainLoadingIndicator';
import MapLoader from './MapLoader';

const Map = (props: Props) => {
    const { hereSettings, hereSettingsError, loadHereSettings } = props;
    useEffect(() => {
        if (!hereSettings) {
            loadHereSettings();
        }
    }, [hereSettings, loadHereSettings]);

    const mapClassName = 'GeofenceAdministrationMap height-100pct';

    return (
        <div className={mapClassName}>
            {hereSettingsError ? (
                <MapErrorState />
            ) : (
                <MapErrorBoundary>
                    <MapLoader fallback={<MainLoadingIndicator />}>
                        <MapWrapper />
                    </MapLoader>
                </MapErrorBoundary>
            )}
        </div>
    );
};

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    hereSettings: getHereCredentials(state),
    hereSettingsError: getHereCredentialsError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadHereSettings: () => dispatch(fetchHereConfig()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const MapContainer = connector(Map);
export default MapContainer;
