import { connect } from 'react-redux';
import { change, clearFields, formValueSelector } from 'redux-form';

import { poiFormElements } from './PoiForm';
import { AddressSearch, IEvent } from '../common/addressSearch/AddressSearch';
import { setMapCenter } from '../../actions/mapActions';
import { Dispatch, State } from '../../../types';
import { getDisplayMessages, getLocale } from '../../../configuration';
import { getHereCredentials, getMapCenter } from '../../reducers/selectors';
import { injectIntl } from 'react-intl';
import { mapToPosition } from '../geofence/addressSearch/utils/hereMapHelper';

export const mapStateToProps = (state: State) => {
    const hereApiKey = getHereCredentials(state)?.apikey || '';
    const userLocale = getLocale(state);
    const address = formValueSelector(poiFormElements.formName)(state, poiFormElements.address);
    return {
        hereApiKey,
        shortLocale: userLocale ? userLocale.split('-')[0] : '',
        value: address ? address.label : undefined,
        currentMapCenter: getMapCenter(state),
        displayMessages: getDisplayMessages(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChange: (event: IEvent | null) => {
        if (event) {
            dispatch(setMapCenter(mapToPosition(event.latitude, event.longitude)));
            dispatch(
                change(poiFormElements.formName, poiFormElements.address, {
                    label: event.label,
                    position: { lat: event.latitude, lng: event.longitude },
                })
            );

            if (!event.label) {
                dispatch(clearFields(poiFormElements.formName, false, false, poiFormElements.address));
            }
        } else {
            dispatch(clearFields(poiFormElements.formName, false, false, poiFormElements.address));
        }
    },
});

const POIAddressSearch = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddressSearch));
export default POIAddressSearch;
