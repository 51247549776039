import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import EventUtils from '@rio-cloud/rio-uikit/EventUtils';
import Marker from '@rio-cloud/rio-uikit/Marker';
import Circle from '@rio-cloud/rio-uikit/Circle';
import Polygon from '@rio-cloud/rio-uikit/Polygon';

import { State } from '../../reducers';
import { Dispatch } from '../../types';
import { geofenceFormElements } from '../components/geofence/GeofenceForm';
import { getEditMode } from '../reducers/selectors';
import { Coordinates } from '../types';
import { GeofencePointMarker } from './Markers';
import { MapEvent, MapApi } from '@rio-cloud/rio-uikit/mapTypes';
import {
    dataLayerPush,
    GAEventName,
    GAEventTrigger,
    GAFlowName,
} from '../../configuration/googleAnalytics/googleAnalytics';
import { dragGeofencePoint } from '../actions/mapActions';
import { geofenceBottomSheetActions } from './redux/geofenceBottomSheet.reducer';

const STROKE_COLOR = 'rgba(90, 72, 118, 1)';
const FILL_COLOR = 'rgba(90, 72, 118, 0.3)';

interface CreationFormFormValues {
    points: Array<Coordinates>;
    activateGeofence: boolean;
    geofenceAddress: string;
    geofenceRadius: number;
    geofenceShape: 'circular' | 'polygonal';
    geofenceName: string;
    suggestionPoint: Coordinates;
}

interface CircularGeofence {
    name: string;
    type: 'circular';
    center: Coordinates;
    radius: number;
}

interface PolygonalGeofence {
    name: string;
    type: 'polygonal';
    points: Array<Coordinates>;
}

type Geofence = CircularGeofence | PolygonalGeofence;

export interface Props {
    editMode: boolean;
    geofence?: Geofence;
    onDrag: (point: Coordinates, index: number) => void;
    mapApi: MapApi;
}

export const MapGeofenceRenderer = ({ editMode, geofence, onDrag, mapApi }: Props) => {
    let dragStart: Coordinates | undefined = undefined;
    let dragPoint: Coordinates | undefined = undefined;

    if (!geofence) {
        return null;
    }

    if (geofence.type === 'polygonal') {
        const eventListenerMap = {
            [EventUtils.DRAG_START]: (event: Event, enhancedEvent: MapEvent) => {
                const target: any = event.target;
                const index = target.data.id;
                dragStart = enhancedEvent.calculateGeoCoords();
                dragPoint = geofence.points[index];
                mapApi.behavior.disable();
            },
            [EventUtils.DRAG_END]: (event: Event, enhancedEvent: MapEvent) => {
                if (!dragStart || !dragPoint) {
                    return;
                }
                const target: any = event.target;
                const index = target.data.id;
                const dragTo = enhancedEvent.calculateGeoCoords();
                const point = {
                    lat: dragPoint.lat - dragStart.lat + dragTo.lat,
                    lng: dragPoint.lng - dragStart.lng + dragTo.lng,
                };
                dataLayerPush({
                    trigger: GAEventTrigger.click,
                    event: GAEventName.polygonalGeofenceMarkerDragged,
                    flow_name: GAFlowName.createGeofence,
                });
                onDrag(point, index);
                mapApi.behavior.enable();
            },
            [EventUtils.DRAG]: (event: Event, ev: MapEvent) => {
                const target: any = event.target;
                target.setGeometry(mapApi.map.screenToGeo(ev.viewportX, ev.viewportY));
            },
        };
        return (
            <Fragment>
                <Polygon points={geofence.points} style={{ strokeColor: STROKE_COLOR, fillColor: FILL_COLOR }} />
                {!editMode
                    ? geofence.points.map((point, index) => {
                          return (
                              <Marker
                                  key={index}
                                  position={point}
                                  icon={<GeofencePointMarker showDragCursor={true}/>}
                                  customData={{ id: index }}
                                  draggable={true}
                                  eventListenerMap={eventListenerMap}
                              />
                          );
                      })
                    : null}
            </Fragment>
        );
    }
    if (geofence.type === 'circular') {
        return (
            <Fragment>
                <Marker position={geofence.center} icon={<GeofencePointMarker />} />
                <Circle
                    position={geofence.center}
                    radius={geofence.radius}
                    style={{ strokeColor: STROKE_COLOR, fillColor: FILL_COLOR }}
                    precision={30}
                />
            </Fragment>
        );
    }
    return null;
};

export const mapStateToProps = (state: State) => {
    const formValues = getFormValues(geofenceFormElements.geofenceFormName)(state)
        ? (getFormValues(geofenceFormElements.geofenceFormName)(state) as CreationFormFormValues)
        : ({} as CreationFormFormValues);

    const getGeofence: () => Geofence | undefined = () => {
        return formValues.geofenceShape === 'circular' && formValues.points && formValues.points.length > 0
            ? {
                  type: 'circular',
                  center: formValues.points[0],
                  radius: formValues.geofenceRadius ? formValues.geofenceRadius : 0,
                  name: formValues.geofenceName ? formValues.geofenceName : '',
              }
            : formValues.geofenceShape === 'polygonal' && formValues.points && formValues.points.length > 0
              ? {
                    type: 'polygonal',
                    points: formValues.points,
                    name: formValues.geofenceName ? formValues.geofenceName : '',
                }
              : undefined;
    };

    return {
        editMode: getEditMode(state) === 'UPDATE',
        geofence: getGeofence(),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    onDrag: (point: Coordinates, index: number) => {
        dispatch(dragGeofencePoint(point, index));
        dispatch(geofenceBottomSheetActions.hideGeofenceBottomSheet());
    },
});

const ConnectedMapGeofenceRenderer = connect(mapStateToProps, mapDispatchToProps)(MapGeofenceRenderer);
ConnectedMapGeofenceRenderer.displayName = 'ConnectedMapGeofenceRenderer';
export default ConnectedMapGeofenceRenderer;
