import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { default as SliderUiKit } from '@rio-cloud/rio-uikit/Slider';

export interface IProps {
    maxValue: number;
    minValue: number;
    disabled?: boolean;
}

class SliderWithInput extends React.Component<WrappedFieldProps & IProps> {
    render() {
        const {
            input: { value, onChange },
            maxValue,
            minValue,
            disabled,
        } = this.props;

        const castToInt = (event: { target: { value: string } }) => {
            if (event.target.value) {
                onChange(parseInt(event.target.value, 10));
            }
        };

        return (
            <div className={'display-flex align-items-center'}>
                <SliderUiKit
                    value={value}
                    onChange={onChange}
                    maxValue={maxValue}
                    minValue={minValue}
                    disabled={disabled}
                />
                <div className={'width-100 margin-left-15'}>
                    <input
                        className={'form-control text-center padding-5 no-controls'}
                        min={minValue}
                        max={maxValue}
                        type={'number'}
                        value={value}
                        onChange={castToInt}
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    }
}

export default SliderWithInput;
