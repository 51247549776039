import { Route, Routes } from 'react-router-dom';

import { Routing } from './Routing';
import POIPage from '../table/POIPage';
import GeofenceCreationSidebarContainer from '../components/geofence/GeofenceSidebar.container';
import GeofenceEditingSidebarContainer from '../components/geofence/GeofenceEditingSidebar.container';
import POICreationSidebarContainer from '../components/poi/POICreationSidebar.container';
import POIEditSidebarContainer from '../components/poi/POIEditSidebar.container';
import MapContainer from '../map/MapContainer';

export const Sidebars = () => {
    return (
        <Routes>
            <Route path={Routing.geofenceCreate} element={<GeofenceCreationSidebarContainer />} />
            <Route path={Routing.geofenceEdit} element={<GeofenceEditingSidebarContainer />} />
            <Route path={Routing.poiCreate} element={<POICreationSidebarContainer />} />
            <Route path={Routing.poiEdit} element={<POIEditSidebarContainer />} />
        </Routes>
    );
};

export const Router = () => {
    return (
        <Routes>
            <Route path={Routing.geofenceCreate} element={<MapContainer/>} />
            <Route path={Routing.geofenceEdit} element={<MapContainer/>} />
            <Route path={Routing.poiCreate} element={<MapContainer/>} />
            <Route path={Routing.poiEdit} element={<MapContainer/>} />
            <Route path={Routing.poiList} element={<POIPage/>} />
            <Route path={'/*'} element={<POIPage/>} />
        </Routes>
    );
};

export default Router;
