import { failedFetchHereConfig, successFetchHereConfig } from '../actions/hereConfigActions';

export interface IHereConfigState {
    credentials?: {
        apikey: string;
    };
    credentialsError?: Error;
}

const INITIALE_STATE = {
    credentials: undefined,
    credentialsError: undefined,
};

export type handledActions = ReturnType<typeof successFetchHereConfig> | ReturnType<typeof failedFetchHereConfig>;

export const hereConfigReducer = (
    state: IHereConfigState = INITIALE_STATE,
    action: handledActions
): IHereConfigState => {
    switch (action.type) {
        case 'SUCCESS_FETCH_HERE_CONFIG':
            return { ...state, ...action.payload };
        case 'FAILED_FETCH_HERE_CONFIG':
            return { ...state, credentialsError: action.payload };
        default:
            return state;
    }
};

export const getHereCredentials = (state: IHereConfigState) => state.credentials;
export const getHereCredentialsError = (state: IHereConfigState) => state.credentialsError;

export default hereConfigReducer;
