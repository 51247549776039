import { IntlProvider } from 'react-intl';

import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

import { DEFAULT_LOCALE } from '../../configuration';

import { AppPropertiesFromDispatch, AppPropertiesFromState } from './App.container';
import HeaderContainer from './Header.container';
import { Router, Sidebars } from './Router';
import { PositionSharingDialogContainer } from '../positionSharingDialog/PositionSharingDialog.container';
import { ConfirmationDialogContainer } from '../components/common/ConfirmationDialogContainer';
import { UrlParsingContainer } from '../components/common/UrlParsing.container';
import { HashRouter } from 'react-router-dom';
import { GeofenceBottomSheetContainer } from '../map/GeofenceBottomSheetContainer';
import React from 'react';

type AppProperties = AppPropertiesFromDispatch & AppPropertiesFromState;

const App = (props: AppProperties) => {
    const { hideSessionDialog, displayMessages, showSessionExpired, userLocale } = props;

    if (!displayMessages) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <HashRouter>
                <ApplicationLayout className={'GeofenceAdministration'}>
                    <ApplicationLayout.Header>
                        <HeaderContainer />
                    </ApplicationLayout.Header>
                    <ApplicationLayout.Sidebar className={'right'}>
                        <Sidebars />
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Body innerClassName={'padding-0'}>
                        <PositionSharingDialogContainer />
                        <Router />
                    </ApplicationLayout.Body>
                    <UrlParsingContainer />
                    <NotificationsContainer />
                    <GeofenceBottomSheetContainer
                        titleTranslationId={'intl-msg:geofence.drag.and.drop.onboarding.title'}
                        contentTranslationId={'intl-msg:geofence.drag.and.drop.onboarding.content'}
                    />
                    <SessionExpiredDialog locale={userLocale} onClose={hideSessionDialog} show={showSessionExpired} />
                    <ConfirmationDialogContainer />
                </ApplicationLayout>
            </HashRouter>
        </IntlProvider>
    );
};

export default App;
