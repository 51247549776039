import { authenticatedGet } from './fetchActions';
import { IHereConfigState } from '../reducers/hereConfigReducer';
import { Dispatch } from '../../types';
import { config } from '../../config';

interface IHereConfigDto {
    items: CredentionalsDto[];
}
interface CredentionalsDto {
    api_key: string;
}

const mapResponseToInternalConfig = (dto: IHereConfigDto) => ({
    credentials: {
        apikey: dto.items[0].api_key,
    },
});

export const successFetchHereConfig = (hereConfig: IHereConfigState) => ({
    type: 'SUCCESS_FETCH_HERE_CONFIG' as 'SUCCESS_FETCH_HERE_CONFIG',
    payload: hereConfig,
});

export const failedFetchHereConfig = (error: Error) => ({
    type: 'FAILED_FETCH_HERE_CONFIG' as 'FAILED_FETCH_HERE_CONFIG',
    payload: error,
});

export const fetchHereConfig = () => {
    const path = `${config.backend.RIO_MAPSERVICE}/configurations`;
    return (dispatch: Dispatch) => {
        return dispatch(authenticatedGet(path))
            .then((response) => {
                dispatch(successFetchHereConfig(mapResponseToInternalConfig(response)));
            })
            .catch((error) => {
                dispatch(failedFetchHereConfig(error));
            });
    };
};
